@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&family=Noto+Sans:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700");
/*----FontAwesome-------*/
@import url("../fonts/font-awesome/css/font-awesome.min.css");
@import "bootstrap/dist/css/bootstrap.css";

// Style Scss
@import "main/modal";
@import "main/portfolio-modal";
@import "main/portfolio";
@import "main/404";
@import "main/preview";
@import "main/circle";
@import "main/slick";
@import "main/switcher";
@import "main/style";
// @import "main/rtl";

// skin
@import "main/skins/yellow";
@import "main/skins/blue";